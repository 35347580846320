import {SectionCard} from "./SectionCard"
import {SectionFour} from "./SectionFour"
import SectionLanguage from "./SectionLanguage"
import {SectionOne} from "./SectionOne"
import {SectionThird} from "./SectionThird"

const MainPageContent = () => {
    return (
        <div className="flex-1 flex flex-col gap-24 lg:gap-36">
            <SectionOne />
            <SectionCard />
            <SectionLanguage />
            <SectionThird />
            <SectionFour />
        </div>
    )
}

export default MainPageContent;