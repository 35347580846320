import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as HintIcon} from '../../../assets/hint.svg';
import {selectFinalSequenceIndex, selectFinalTrainingSequence} from "../../../store/training/trainigSelector";
import {clearTrainingCache, incrementFinalSequenceIndex} from "../../../store/training/trainingSlice";
import TitleCard from "../../Cards/TitleCard";
import {useTranslation} from "react-i18next";
import TrainingClosePopup from "../TrainingClosePopup";
import {motion} from "framer-motion";

type TrainingBlockProps = {
    title?: string;
    children: string | string[] | JSX.Element | JSX.Element[];
    showHint?: () => void;
    isHint?: boolean;
    showCorrectAnswer: () => void;
    isSkipped: boolean | undefined;
    isCorrect: boolean;
    handleSkipClick: () => void;
}



const BackButton = ({ onBackClick, className }: { onBackClick: () => void, className: string }) => {
    const [slidingForm, setSlidingForm] = React.useState(false);

    const click = () => {
        setSlidingForm(true)
    }

    return (
        <>
            <button className={`flex items-start justify-center sm:top-[10px] top-[0px] pl-5 left-0 z-50 absolute xl:relative ${className}`} onClick={click}>
                <CloseIcon
                    className="!w-6 !h-6 sm:!w-9 absolute sm:!h-9 hover:bg-gray-100 image-full p-0 md:p-[4px] text-color-big-text rounded-full duration-300 ease-out transition"
                />
            </button>
            {slidingForm &&
                <TrainingClosePopup setSlidingForm={setSlidingForm} onBackClick={onBackClick}/>
            }

        </>
    )
}


const TrainingBlock = ({
    title,
    children,
    showHint,
    showCorrectAnswer,
    isSkipped,
    isHint,
    isCorrect,
    handleSkipClick,
}: TrainingBlockProps) => {
    console.log("🚀 ~ isSkipped:", isSkipped)
    console.log("🚀 ~ isCorrect:", isCorrect)
    const dispatch = useDispatch();

    const skipButtonRef = useRef<HTMLButtonElement>(null);
    const hintButtonRef = useRef<HTMLButtonElement>(null);
    const finalTrainingSequence = useSelector(selectFinalTrainingSequence)
    const trainingSequenceIndex = useSelector(selectFinalSequenceIndex);
    const progress = trainingSequenceIndex * 100 / finalTrainingSequence.length;
    const continueButtonRef = useRef<HTMLButtonElement>(null);
    const {t} = useTranslation()
        
    const onBackClick = () => {
        dispatch(clearTrainingCache());
    }

    useEffect(() => {
        if (skipButtonRef.current) {
            if (isSkipped || isCorrect) {
                skipButtonRef.current.disabled = true;
            }
            skipButtonRef.current.disabled = true;
        }
    }, [isCorrect, isSkipped]);

    useEffect(() => {
        if (hintButtonRef.current) {
            if (isCorrect || isSkipped || isHint) {
                hintButtonRef.current.disabled = true;
            }
        }
    }, [isCorrect, isHint, isSkipped]);

    useEffect(() => {
        if (continueButtonRef.current) {
            if (isSkipped) {
                continueButtonRef.current.disabled = true;
                setTimeout(() => {
                    if (continueButtonRef.current) continueButtonRef.current.disabled = false;
                    console.log("🚀 ~ continueButtonRef.current:", continueButtonRef.current)
                    console.log("🚀 ~ continueButtonRef.current:", continueButtonRef.current?.disabled)
                }, 1000)
            } else {
                continueButtonRef.current.disabled = true;
            }
        }
    }, [isSkipped])

    const handleHintClick = () => {
        if (!isHint) {
            showHint && showHint();
        }
    }

    const handleSkipClicks = () => {
        showCorrectAnswer();
        dispatch(incrementFinalSequenceIndex());
    }

    return (
        <div className="w-full flex flex-col  h-[100vh] md:h-full">
            <div className="flex flex-col justify-between items-center">
                <div className="flex flex-row items-сenter max-xl:justify-center justify-between w-full mx-auto my-0 max-w-[1084px] max-lg:mt-5 max-md:mx-0">
                    <BackButton onBackClick={onBackClick} className="max-lg:mt-5 ml-3" />
                    <LinearProgressWithLabel value={progress} />
                    <button
                        style={{ pointerEvents: isCorrect || isSkipped || isHint ? "none" : "all" }}
                        className="flex items-start justify-center z-10"
                        onClick={handleHintClick}
                    >
                        {showHint && <HintIcon
                            className="w-6 h-6 max-lg:mt-5 mr-3 cursor-pointer absolute xl:relative sm:top-[10px] top-[0px] right-[5px] hover:fill-yellow-V300 sm:w-7 sm:h-7" />}
                    </button>
                </div>
                <TitleCard title={title} >
                    <div
                        className="flex min-w-full px-4 w-full  min-h-full justify-start md:mb-10 my-10 md:justify-center items-center flex-wrap flex-col gap-0 lg:gap-4">
                        {children}
                    </div>
                </TitleCard>
            </div>
            <div className="">
                <div className="flex justify-center flex-grow-0">  {!isSkipped ? (

                    <motion.button
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        style={{ pointerEvents: isCorrect ? "none" : "all" }}
                        onClick={handleSkipClick}
                        onChange={handleSkipClicks}
                        className={`btn-all btn-md cursor-pointer text-center p-3 w-64 rounded hover:text-white bg-button-color my-4 hover:bg-button-color`}
                    >
                        <span className="text-white font-bold text-xl">{t('Skip')}</span>
                    </motion.button>
                ) : (
                    <motion.button
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        transition={{duration: 0.5}}
                        style={{ pointerEvents: !isSkipped ? "none" : "all" }}
                        onClick={handleSkipClick}
                        onChange={handleSkipClicks}
                        className={`btn-all btn-md cursor-pointer text-center p-3 w-64 rounded hover:text-white bg-button-color my-4  hover:bg-button-color`}
                    >
                        <button ref={continueButtonRef} disabled={!isSkipped}>
                            <p className="text-white font-bold text-xl">{t('Continue')}</p>
                        </button>
                    </motion.button>
                )}
                </div>
            </div>
        </div>
    );
}
export default TrainingBlock;

export const LinearProgressWithLabel: React.FC<{ value: number }> = ({ value }) => {
    return (
        <div className="loaders w-[30%] max-md:w-[50%] mt-1 lg:mt-6">
            <div className="progress-bars correct" style={{ width: `${value}%` }}></div>
        </div>
    );
};


//// 2024-10-10T20:51:27.188+02:00  WARN 18836 --- [ TaskExecutor-4] o.h.engine.jdbc.spi.SqlExceptionHelper   : SQL Error: 0, SQLState: 23505
// //2024-10-10T20:51:27.188+02:00 ERROR 18836 --- [ TaskExecutor-4] o.h.engine.jdbc.spi.SqlExceptionHelper   : ERROR: duplicate key value violates unique constraint "uk_6y3pefblva2dpxoo1h3remb34"
// //  Detail: Key (training_examples_id)=(1702) already exists.
// //An exception happened in TrainingExampleService in createTrainingExampleAnSave() 	wordExampleTranslation wasn't saved properly
// //identifier of an instance of com.qualitypaper.fluentfusion.model.vocabulary.learning.training.Training was altered from 724 to 720
// //2024-10-10T20:51:27.247+02:00 ERROR 18836 --- [ TaskExecutor-4] .a.i.SimpleAsyncUncaughtExceptionHandler : Unexpected exception occurred invoking async method: public void com.qualitypaper.fluentfusion.service.vocabulary.userVocabulary.UserVocabularyService.generateExamplesForTraining(com.qualitypaper.fluentfusion.model.vocabulary.UserVocabulary,int)
// //
// //org.springframework.dao.DataIntegrityViolationException: could not execute statement [ERROR: duplicate key value violates unique constraint "uk_6y3pefblva2dpxoo1h3remb34"
// //  Detail: Key (training_examples_id)=(1702) already exists.] [insert into training_training_example (training_id,training_examples_id) values (?,?)]; SQL [insert into training_training_example (training_id,training_examples_id) values (?,?)]; constraint [uk_6y3pefblva2dpxoo1h3remb34]
// //	at org.springframework.orm.jpa.vendor.HibernateJpaDialect.convertHibernateAccessException(HibernateJpaDialect.java:290) ~[spring-orm-6.1.1.jar:6.1.1]
// //	at org.springframework.orm.jpa.vendor.HibernateJpaDialect.translateExceptionIfPossible(HibernateJpaDialect.java:241) ~[spring-orm-6.1.1.jar:6.1.1]
// //	at org.springframework.orm.jpa.JpaTransactionManager.doCommit(JpaTransactionManager.java:565) ~[spring-orm-6.1.1.jar:6.1.1]
// //	at org.springframework.transaction.support.AbstractPlatformTransactionManager.processCommit(AbstractPlatformTransactionManager.java:794) ~[spring-tx-6.1.1.jar:6.1.1]
// //	at org.springframework.transaction.support.AbstractPlatformTransactionManager.commit(AbstractPlatformTransactionManager.java:757) ~[spring-tx-6.1.1.jar:6.1.1]
// //	at org.springframework.transaction.interceptor.TransactionAspectSupport.commitTransactionAfterReturning(TransactionAspectSupport.java:669) ~[spring-tx-6.1.1.jar:6.1.1]
// //	at org.springframework.transaction.interceptor.TransactionAspectSupport.invokeWithinTransaction(TransactionAspectSupport.java:419) ~[spring-tx-6.1.1.jar:6.1.1]
// //	at org.springframework.transaction.interceptor.TransactionInterceptor.invoke(TransactionInterceptor.java:119) ~[spring-tx-6.1.1.jar:6.1.1]
// //	at org.springframework.aop.framework.ReflectiveMethodInvocation.proceed(ReflectiveMethodInvocation.java:184) ~[spring-aop-6.1.1.jar:6.1.1]
// //	at org.springframework.dao.support.PersistenceExceptionTranslationInterceptor.invoke(PersistenceExceptionTranslationInterceptor.java:137) ~[spring-tx-6.1.1.jar:6.1.1]
// //	at org.springframework.aop.framework.ReflectiveMethodInvocation.proceed(ReflectiveMethodInvocation.java:184) ~[spring-aop-6.1.1.jar:6.1.1]
// //	at org.springframework.data.jpa.repository.support.CrudMethodMetadataPostProcessor$CrudMethodMetadataPopulatingMethodInterceptor.invoke(CrudMethodMetadataPostProcessor.java:164) ~[spring-data-jpa-3.2.0.jar:3.2.0]
// //	at org.springframework.aop.framework.ReflectiveMethodInvocation.proceed(ReflectiveMethodInvocation.java:184) ~[spring-aop-6.1.1.jar:6.1.1]
// //	at org.springframework.aop.interceptor.ExposeInvocationInterceptor.invoke(ExposeInvocationInterceptor.java:97) ~[spring-aop-6.1.1.jar:6.1.1]
// //	at org.springframework.aop.framework.ReflectiveMethodInvocation.proceed(ReflectiveMethodInvocation.java:184) ~[spring-aop-6.1.1.jar:6.1.1]
// //	at org.springframework.aop.framework.JdkDynamicAopProxy.invoke(JdkDynamicAopProxy.java:249) ~[spring-aop-6.1.1.jar:6.1.1]
// //	at jdk.proxy2/jdk.proxy2.$Proxy163.save(Unknown Source) ~[na:na]
// //	at com.qualitypaper.fluentfusion.service.vocabulary.learning.TrainingService.save(TrainingService.java:128) ~[classes/:na]
// //	at com.qualitypaper.fluentfusion.service.vocabulary.userVocabulary.UserVocabularyService.generateExamplesForTraining(UserVocabularyService.java:831) ~[classes/:na]
// //	at java.base/jdk.internal.reflect.DirectMethodHandleAccessor.invoke(DirectMethodHandleAccessor.java:103) ~[na:na]
// //	at java.base/java.lang.reflect.Method.invoke(Method.java:580) ~[na:na]
// //	at org.springframework.aop.support.AopUtils.invokeJoinpointUsingReflection(AopUtils.java:352) ~[spring-aop-6.1.1.jar:6.1.1]
// //	at org.springframework.aop.framework.ReflectiveMethodInvocation.invokeJoinpoint(ReflectiveMethodInvocation.java:196) ~[spring-aop-6.1.1.jar:6.1.1]
// //	at org.springframework.aop.framework.ReflectiveMethodInvocation.proceed(ReflectiveMethodInvocation.java:163) ~[spring-aop-6.1.1.jar:6.1.1]
// //	at org.springframework.aop.framework.CglibAopProxy$CglibMethodInvocation.proceed(CglibAopProxy.java:765) ~[spring-aop-6.1.1.jar:6.1.1]
// //	at org.springframework.aop.interceptor.AsyncExecutionInterceptor.lambda$invoke$0(AsyncExecutionInterceptor.java:115) ~[spring-aop-6.1.1.jar:6.1.1]
// //	at java.base/java.util.concurrent.FutureTask.run(FutureTask.java:317) ~[na:na]
// //	at java.base/java.util.concurrent.ThreadPoolExecutor.runWorker(ThreadPoolExecutor.java:1144) ~[na:na]
// //	at java.base/java.util.concurrent.ThreadPoolExecutor$Worker.run(ThreadPoolExecutor.java:642) ~[na:na]
// //	at java.base/java.lang.Thread.run(Thread.java:1570) ~[na:na]
// //Caused by: org.hibernate.exception.ConstraintViolationException: could not execute statement [ERROR: duplicate key value violates unique constraint "uk_6y3pefblva2dpxoo1h3remb34"
// //  Detail: Key (training_examples_id)=(1702) already exists.] [insert into training_training_example (training_id,training_examples_id) values (?,?)]
// //	at org.hibernate.exception.internal.SQLStateConversionDelegate.convert(SQLStateConversionDelegate.java:95) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.exception.internal.StandardSQLExceptionConverter.convert(StandardSQLExceptionConverter.java:56) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.engine.jdbc.spi.SqlExceptionHelper.convert(SqlExceptionHelper.java:108) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.engine.jdbc.internal.ResultSetReturnImpl.executeUpdate(ResultSetReturnImpl.java:278) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.engine.jdbc.mutation.internal.AbstractMutationExecutor.performNonBatchedMutation(AbstractMutationExecutor.java:107) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.engine.jdbc.mutation.internal.MutationExecutorSingleNonBatched.performNonBatchedOperations(MutationExecutorSingleNonBatched.java:40) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.engine.jdbc.mutation.internal.AbstractMutationExecutor.execute(AbstractMutationExecutor.java:52) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.persister.collection.mutation.InsertRowsCoordinatorStandard.insertRows(InsertRowsCoordinatorStandard.java:116) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.persister.collection.BasicCollectionPersister.recreate(BasicCollectionPersister.java:113) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.action.internal.CollectionUpdateAction.execute(CollectionUpdateAction.java:80) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.engine.spi.ActionQueue.executeActions(ActionQueue.java:635) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.engine.spi.ActionQueue.executeActions(ActionQueue.java:502) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.event.internal.AbstractFlushingEventListener.performExecutions(AbstractFlushingEventListener.java:358) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.event.internal.DefaultFlushEventListener.onFlush(DefaultFlushEventListener.java:39) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.event.service.internal.EventListenerGroupImpl.fireEventOnEachListener(EventListenerGroupImpl.java:127) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.internal.SessionImpl.doFlush(SessionImpl.java:1412) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.internal.SessionImpl.managedFlush(SessionImpl.java:485) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.internal.SessionImpl.flushBeforeTransactionCompletion(SessionImpl.java:2301) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.internal.SessionImpl.beforeTransactionCompletion(SessionImpl.java:1966) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.engine.jdbc.internal.JdbcCoordinatorImpl.beforeTransactionCompletion(JdbcCoordinatorImpl.java:439) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.resource.transaction.backend.jdbc.internal.JdbcResourceLocalTransactionCoordinatorImpl.beforeCompletionCallback(JdbcResourceLocalTransactionCoordinatorImpl.java:169) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.resource.transaction.backend.jdbc.internal.JdbcResourceLocalTransactionCoordinatorImpl$TransactionDriverControlImpl.commit(JdbcResourceLocalTransactionCoordinatorImpl.java:267) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.hibernate.engine.transaction.internal.TransactionImpl.commit(TransactionImpl.java:101) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	at org.springframework.orm.jpa.JpaTransactionManager.doCommit(JpaTransactionManager.java:561) ~[spring-orm-6.1.1.jar:6.1.1]
// //	... 27 common frames omitted
// //Caused by: org.postgresql.util.PSQLException: ERROR: duplicate key value violates unique constraint "uk_6y3pefblva2dpxoo1h3remb34"
// //  Detail: Key (training_examples_id)=(1702) already exists.
// //	at org.postgresql.core.v3.QueryExecutorImpl.receiveErrorResponse(QueryExecutorImpl.java:2725) ~[postgresql-42.7.3.jar:42.7.3]
// //	at org.postgresql.core.v3.QueryExecutorImpl.processResults(QueryExecutorImpl.java:2412) ~[postgresql-42.7.3.jar:42.7.3]
// //	at org.postgresql.core.v3.QueryExecutorImpl.execute(QueryExecutorImpl.java:371) ~[postgresql-42.7.3.jar:42.7.3]
// //	at org.postgresql.jdbc.PgStatement.executeInternal(PgStatement.java:502) ~[postgresql-42.7.3.jar:42.7.3]
// //	at org.postgresql.jdbc.PgStatement.execute(PgStatement.java:419) ~[postgresql-42.7.3.jar:42.7.3]
// //	at org.postgresql.jdbc.PgPreparedStatement.executeWithFlags(PgPreparedStatement.java:194) ~[postgresql-42.7.3.jar:42.7.3]
// //	at org.postgresql.jdbc.PgPreparedStatement.executeUpdate(PgPreparedStatement.java:155) ~[postgresql-42.7.3.jar:42.7.3]
// //	at com.zaxxer.hikari.pool.ProxyPreparedStatement.executeUpdate(ProxyPreparedStatement.java:61) ~[HikariCP-5.0.1.jar:na]
// //	at com.zaxxer.hikari.pool.HikariProxyPreparedStatement.executeUpdate(HikariProxyPreparedStatement.java) ~[HikariCP-5.0.1.jar:na]
// //	at org.hibernate.engine.jdbc.internal.ResultSetReturnImpl.executeUpdate(ResultSetReturnImpl.java:275) ~[hibernate-core-6.2.9.Final.jar:6.2.9.Final]
// //	... 47 common frames omitted