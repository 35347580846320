import {ReactElement} from "react";


function UnauthorizedFormComponent({ children }: Readonly<{ children: ReactElement[] | ReactElement | undefined }>) {

    return (
        <div className="min-h-screen bg-white lg:bg-slate-100 flex sm:items-center items-start max-sm:pt-20">
            <div className="card mx-auto w-full max-w-xl shadow-0 lg:shadow-xl">
                {children}
            </div>
        </div>
    )
}

export default UnauthorizedFormComponent;