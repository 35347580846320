import {ChangeEvent, useState} from "react";
import {useDispatch} from "react-redux";
import {SOUND_TRAINING} from "../../../../constant";
import {
    incrementFinalSequenceIndex,
    incrementHintsNumber,
    incrementMistakeNumber
} from "../../../../store/training/trainingSlice";
import {getBlockWidth} from "../../../../utils/globalUtils";
import {playSound, removeSpecialCharacters, revealAnswer} from "../../../../utils/trainingUtils";
import {TrainingTypesProps} from "../../Training";
import TrainingBlock from "../TrainingBlock";
import TrainingImage from "../TrainingImage";
import TrainingInput from "./CompleteEmptySpacesInput";
import {motion} from "framer-motion";

const TranslationTraining = ({ targetWord, elem }: TrainingTypesProps) => {
    const dispatch = useDispatch();
    const [input, setInput] = useState<string>("");
    const [correct, setCorrect] = useState<boolean>(false);
    const [skipped, setSkipped] = useState<boolean>(false);


    const onChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value)
        if (removeSpecialCharacters(e.target.value).toLowerCase() === elem.trainingExample.identifiedWord.toLowerCase()) {
            completeTraining(false);
        }
    }

    const completeTraining = (localSkipped: boolean) => {
        setCorrect(true)

        playSound(SOUND_TRAINING)
            .then(message => console.log(message))
            .catch(error => console.error("Error:", error));

        playSound(targetWord.wordTranslation.wordFrom.soundUrl)
            .then(message => console.log(message))
            .catch(error => console.error("Error:", error));

        // completeInputTrainingRef(inputRef)

        if (!localSkipped) {
            console.log('auto-sip')
            setTimeout(() => {
                setInput("")
                setCorrect(false)
                setSkipped(false)
                dispatch(incrementFinalSequenceIndex())
            }, 1000)
        }
    }
    const showCorrectAnswer = () => {
        setInput(elem.trainingExample.identifiedWord);
        setCorrect(true);
        revealAnswer(setInput, elem.trainingExample.identifiedWord, elem)
        dispatch(incrementMistakeNumber({ index: elem.index, trainingType: elem.trainingExample.trainingType }))
        completeTraining(true)
        setSkipped(true)
    }

    const showHint = () => {
        setInput(elem.trainingExample.identifiedWord.slice(0, 2));
        dispatch(incrementHintsNumber({ index: elem.index, trainingType: elem.trainingExample.trainingType }))
    }

    const handleSkipClick = () => {
        if (correct) {
            setInput("")
            setSkipped(false);
            setCorrect(false)
            dispatch(incrementFinalSequenceIndex())
        } else {
            showCorrectAnswer()
        }
    }

    return (

        <TrainingBlock showCorrectAnswer={showCorrectAnswer} isSkipped={skipped} isCorrect={correct}
            handleSkipClick={handleSkipClick} showHint={showHint}>
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 0.5}} className={`flex flex-col items-center`}>
            <TrainingImage src={targetWord.wordTranslation.wordFrom.imageUrl} />
            <span className={`text-2xl text-center mt-4`}>
                {targetWord.wordTranslation.wordTo.word}
            </span>
            <TrainingInput correct={correct} input={input} onChangeEvent={onChangeEvent}
                width={getBlockWidth(elem.trainingExample.identifiedWord)} trainingExampleId={elem.trainingExample.id} />
           </motion.div>
        </TrainingBlock>
    )
}

export default TranslationTraining