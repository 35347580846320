import axios from "axios";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as Learn} from "../assets/pingwin3.svg";
import {BASE_URL} from "../constant";
import i18n from "../i18nf/i18n";
import {
    selectSelectedLearningLanguage,
    selectSelectedNativeLanguage,
    setSelectedNativeLanguage
} from "../store/languageSlice";
import {selectToken} from "../store/user/userSelector";
import {pickIcon} from "../utils/iconUtils";
import {FormLanguageButton} from "./FormLearning";

const FormNative = () => {
    const dispatch = useDispatch();
    const nativeLanguage = useSelector(selectSelectedNativeLanguage);
    const learningLanguage = useSelector(selectSelectedLearningLanguage)

    const token = useSelector(selectToken);
    const { t } = useTranslation()

    function handleLanguageSelect(language: string) {
        dispatch(setSelectedNativeLanguage(language));
    };

    async function submit() {
        const res = await axios.post(`${BASE_URL}/vocabulary/create`, {
            nativeLanguage: nativeLanguage,
            learningLanguage: learningLanguage,
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }).then(res => {
            console.log("🚀 ~ res:", res.data);
            return res.data
        });

        const { id } = res;

        window.location.href = `/${i18n.language}/app/vocabularies/${id}`;
    }

    return (
        <div className="w-full max-w-[64rem] m-auto">
            <div
                className="bg-white shadow-xl rounded-md border-2 border-gray-200 gap-10 lg:gap-16  p-4 flex flex-col lg:flex-row justify-between m-4"
            >
                <div className="flex justify-center  lg:order-last">
                    <Learn className="w-[300px] lg:mt-0 lg:w-[350px]" />
                </div>

                <div className="flex flex-col w-full gap-6 justify-between">
                    <div className="flex flex-col gap-4">
                        <p className="text-3xl font-bold text-center lg:text-3xl">
                            {t("ChooseNativeLanguage")}
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                            {learningLanguage !== "ENGLISH" && <FormLanguageButton handleLanguageSelect={handleLanguageSelect} language="ENGLISH" showLanguage="English"
                                icon={pickIcon("ENGLISH", false, "w-14 h-8")} selected={nativeLanguage === "ENGLISH"} />}
                            
                            {learningLanguage !== "GERMAN" && <FormLanguageButton handleLanguageSelect={handleLanguageSelect} language="GERMAN" showLanguage="Deutsch"
                                icon={pickIcon("GERMAN", false, "w-14 h-8")} selected={nativeLanguage === "GERMAN"} />}
                                
                            {learningLanguage !== "SPANISH" && <FormLanguageButton handleLanguageSelect={handleLanguageSelect} language="SPANISH" showLanguage="Español"
                                icon={pickIcon("SPANISH", false, "w-14 h-8")} selected={nativeLanguage === "SPANISH"} />}
                        </div>
                    </div>

                    <button onClick={submit}
                        className="w-full btn btn-all border-2 border-blue-400 bg-blue-500 hover:bg-blue-500 hover:border-blue-400 text-white uppercase font-bold">
                        {t("Continue")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FormNative;
