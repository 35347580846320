import axios from "axios"
import {BASE_URL} from "../constant";

const initializeApp = () => {
    axios.defaults.baseURL = BASE_URL

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
    } else {

        // Removing console.log from prod
        console.log = () => {};
    }
}

export default initializeApp