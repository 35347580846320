import {store} from '../app/store';
import {WordIndex} from '../components/Training/trainingTypes/standard/PhraseConstruction';
import {addToFinalTrainingSequence, incrementMistakeNumber,} from '../store/training/trainingSlice';
import {TrainingExampleWithIndex} from '../store/training/trainingTypes';
import {axios_post} from './globalUtils';

export type TrainingDifficulty = 'standard' | 'hard';

export type Ref<T> = React.RefObject<T>

export type HtmlInputElement = HTMLInputElement | null;
export type HtmlImageElement = HTMLImageElement | null;

export const completeInputTrainingRef = (ref: Ref<HtmlInputElement>) => {
    if (ref.current) {
        ref.current.style.border = '';
        ref.current.disabled = true;
    }
}

export const hintTriggerEvent = (setInput: (text: string) => void, word: string, imageRef?: Ref<HtmlImageElement>) => {
    setInput(word.slice(0, 2));
    imageRef && revealImage(imageRef)
}

export const revealAnswer = (setInput: (text: string) => void, word: string, elem: TrainingExampleWithIndex, imageRef?: Ref<HtmlImageElement>): void => {
    setInput(word);
    imageRef && revealImage(imageRef)
    store.dispatch(addToFinalTrainingSequence(elem))
    store.dispatch(incrementMistakeNumber({index: elem.index, trainingType: elem.trainingExample.trainingType}))
}

export const revealImage = (imageRef: Ref<HtmlImageElement>) => {
    if (imageRef?.current) {
        imageRef.current.style.visibility = 'visible';
    }
}

export const sendCompleteMessage = async (body: {}, token: string) => {
    return await axios_post('/learning/complete', body, {
        headers: {
            Authorization: "Bearer " + token
        }
    })
}

export const removeSpecialCharacters = (text: string) => {
    let regex = /[^A-Za-z\xC0-\xFF\u00C0-\u017F\s\p{vL}?]/ig

    return text.replace(regex, '');
}

export const insert = (array: any, object: WordIndex) => {
    const temp = [...array]
    temp[object.index] = object;
    // return [...array.slice(0, object.index), object, ...array.slice(object.index)]
    return temp;
}

export const constructMap = (object: {}): Map<string, string[]> => {
    if (!object) return new Map<string, string[]>();

    const map = new Map<string, string[]>();

    Object.entries(object).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            map.set(key, value as string[]);
        } else {
            map.set(key, []);
        }
    });

    return map;
}
export const playSound = async (source: string | undefined) => {
    if (!source) return;
    const audio = new Audio(source);
    try {
        await new Promise((resolve, reject) => {
            audio.onended = resolve;
            audio.onabort = reject;
            audio.play();
        });
    } catch (error: any) {
        console.error("Error appeared in playSound()", error)
    }

    return "audio played"
}

export const fetchTraining = async (training: { vocabularyId: number, vocabularyGroupId: number }) => {
    return await axios_post(`/learning/generate`, training, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + store.getState().user.token
        }
    });
}

function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export const completeTraining = async (finalTrainingSequence: TrainingExampleWithIndex[], trainingSessionId: number, token: string) => {
    const mistakes: {
        trainingId: number,
        mistakes: CompleteTrainingSecondLevelResponse[]
    }[] = []


    const groupedByTrainingId = groupBy(finalTrainingSequence, (training) => training.trainingId)

    for (let [key, value] of groupedByTrainingId) {
        const visited: number[] = []
        const temp: CompleteTrainingSecondLevelResponse[] = []

        for (let elem of value) {
            if (visited.includes(elem.trainingExample.id)) continue ;

            visited.push(elem.trainingExample.id)
            temp.push({
                trainingExampleId: elem.trainingExample.id,
                hint: elem.hint,
                skipped: elem.skipped,
                mistakeCount: elem.mistakeCount
            });
        }

        mistakes.push({
            trainingId: key,
            mistakes: [...temp]
        })
    }


    const json = {
        trainingSessionId,
        mistakes: [...mistakes]
    }

    return await sendCompleteMessage(json, token)
}

export type CompleteTrainingSecondLevelResponse = {
    trainingExampleId: number,
    hint: boolean,
    skipped: boolean,
    mistakeCount: number
}

export const compareCollapsed = (first: string, second: string) => {
    return removeSpecialCharacters(first.toLowerCase()).replaceAll(" ", "")
        === removeSpecialCharacters(second.toLowerCase()).replaceAll(" ", "");
}