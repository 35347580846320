import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ReactComponent as Learn} from "../../assets/7648972.svg";
import {ReactComponent as MainPageArrow} from '../../assets/mainpage-arrow.svg';

export const SectionOne = () => {
	
	const isValid = JSON.parse(sessionStorage.getItem('isValided') ?? "{}").valid;

	const { t } = useTranslation();

	return (
		<div className="h-full w-full max-w-[64rem] m-auto mt-4 justify-between">
			<div className="flex justify-between flex-col sm:flex-row  lg:gap-24 items-center gap-0 sm:gap-0 h-100vh ">
				<Learn className="w-[225px] h-[100%] sm:w-[500px] smw-full max-w-[64rem] m-auto py-[18.3px] flex justify-between max-md:justify-around items-center sm:order-last lg:mt-0" />

				<div className="flex flex-col w-full justify-center p-4 xl:p-0">
					<div className="flex flex-col gap-4">
						<h1 className="font-black  mt-4  text-4xl lg:text-5xl "> {t("SectionOneMainText")}
							<span className="text-color-big-text">{t('SectionOneContinuedText')}</span>
						</h1>
						<p className="font-medium text-xl">{t("SectionOneSubText")}</p>
					</div>
					<div className="flex gap-2 mt-4">
						{
							isValid ? (
								<Link to="app/vocabularies" className="flex btn-started rounded-md justify-center items-center w-full sm:w-[12rem]">
									<p className="p-2 font-bold text-white whitespace-nowrap text-sm">{t("GoToAccount")}</p>
									<MainPageArrow />
								</Link>
							) : (
								<Link to="login" className="flex btn-started rounded-md justify-center items-center w-full sm:w-[12rem]">
									<p className="p-2 font-bold text-white ">{t("SectionOneButtonText")}</p>
									<MainPageArrow />
								</Link>
							)
						}
					</div>
				</div>
			</div>
		</div>
	)
}
