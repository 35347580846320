import {ReactComponent as Penguin} from "../../assets/pingwin10.svg";
import {useTranslation} from "react-i18next";

export const SectionThird = () => {
	const { t } = useTranslation();
	return (
		<section className="w-full h-full">
			<div className="content w-full max-w-[64rem] m-auto justify-between">
				<div className="flex justify-between flex-col sm:flex-row items-center gap-0 sm:gap-14 mt-4">
					<Penguin className="w-[225px] h-[100%] sm:w-[500px] smw-full max-w-[64rem] m-auto py-[18.3px] flex justify-between max-md:justify-around items-center sm:order-last lg:mt-0" />
					<div className="flex flex-col w-full justify-center">
						<div className="flex flex-col gap-4  p-4 xl:p-0">
							<h1 className="font-black text-blue-600 text-4xl lg:text-5xl mt-4">{t('SectionThirdMainText')}</h1>
							<p className="font-medium text-xl">{t('SectionThirdSubText')}</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}