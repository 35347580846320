import React, {ReactNode} from "react";
import {ReactComponent as EnglishIcon} from "../../assets/flag-united-kingdom.svg"
import {ReactComponent as SpainIcon} from "../../assets/flag-spain.svg"
import {ReactComponent as GermanIcon} from "../../assets/flag-germany.svg"
import {ReactComponent as Pingwin3} from "../../assets/pingwin9.svg"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";

export const LanguageButton = ({ sectionName, icon, t }: { sectionName: string, icon: ReactNode, t: TFunction<"translation", null> }) => {

	return (
		<div
			className="btn-flag hover:bg-blue-50 transition-all duration-100">
			<div className="flex items-center">
				{icon}
				<h2 className="text-lg font-semibold">{t(sectionName)}</h2>
			</div>
		</div>
	)
}

const SectionLanguage = () => {
	const { t } = useTranslation();

	return (
		<section className="bg-gradient-to-r from-blue-800 via-blue-500 to-blue-800">
			<div
				className="max-w-[64rem] p-4 xl:p-0 mx-auto gap-0 sm:gap-0 lg:gap-24 flex justify-between items-center sm:items-start flex-col sm:flex-row">
				<div>
					<Pingwin3 className="w-[200px] h-[100%] sm:w-[250px]  max-w-[64rem] m-auto py-[18.3px] flex justify-between max-md:justify-around items-center lg:mt-0" />
				</div>
				<div className="p-4 lg:p-0">
					<h3 className=" font-bold mb-8 mt-4 text-4xl lg:text-5xl text-white ">{t("SectionLanguageMainText")} <span
						className="text-blue-100"> {t("SectionLanguageContinuedText")}</span></h3>
					<Link to="login" className="grid grid-cols-1 md:grid-cols-2">
						<LanguageButton t={t} icon={<EnglishIcon className="w-14 h-8" />} sectionName="SectionLanguageOne" />
						<LanguageButton t={t} icon={<SpainIcon className="w-14 h-8" />} sectionName="SectionLanguageTwo" />
						<LanguageButton t={t} icon={<GermanIcon className="w-14 h-8" />} sectionName="SectionLanguageThree" />
					</Link>
				</div>
			</div>
		</section>
	)
}

export default SectionLanguage