import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from "react-router-dom"
import {ReactComponent as Pingwin} from "../../assets/pingwin2.svg"
import i18n from "../../i18nf/i18n"
import {selectHintsCount, selectMistakesCount} from "../../store/training/trainigSelector"
import {clearTrainingCache} from "../../store/training/trainingSlice"
import {TrainingExampleWithIndex} from "../../store/training/trainingTypes"
import {completeTraining} from "../../utils/trainingUtils"
import {WrappedTitleCard} from "../Cards/TitleCard"
import {selectToken} from "../../store/user/userSelector";
import TrophyAnimation from "../../assets/TrophyAnimation.svg";
import {motion} from "framer-motion";


type InformationalCardsType = {
    value: string,
    progress: number,
}

export const InformationalCards = ({ value, progress }: InformationalCardsType) => {
    return (
        <div className="bg-white border border-gray-300 rounded-lg p-4 my-2 shadow-md w-72">
            <span className="text-center block mb-2">{value}</span>
            <div className="w-full bg-gray-200 rounded-lg h-4 overflow-hidden">
                <div
                    className="bg-blue-400 h-full text-xs leading-none text-center text-white rounded-lg transition-all duration-300"
                    style={{ width: `${progress}%` }}
                >
                </div>
            </div>
        </div>
    );
};

const TrainingComplete = ({ finalSequence, trainingSessionId }: {
    finalSequence: TrainingExampleWithIndex[],
    trainingSessionId: number
}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const numberOfMistakes = useSelector(selectMistakesCount)
    const numberOfHints = useSelector(selectHintsCount)
    const totalExercisesCount = finalSequence.length;
    const [accuracyCount, setAccuracyCount] = useState<number>();
    const token = useSelector(selectToken)
    const { t } = useTranslation();

    const clickHandler = (url: string) => {
        dispatch(clearTrainingCache())
        navigate(url)
    }

    const accuracyProgress = accuracyCount ?? 0;

    const continueTraining = async () => {
        dispatch(clearTrainingCache())
        navigate(`/${i18n.language}/training`)
    }

    useEffect(() => {
        let temp: number = Number.parseInt(String(numberOfMistakes !== 0 ? 100 - ((numberOfMistakes) * 100) / (totalExercisesCount) : 100));
        if (temp <= 20) temp += 10;
        setAccuracyCount(temp)
    }, [accuracyCount, numberOfMistakes, totalExercisesCount])

    useEffect(() => {
        const complete = async () => {
            return await completeTraining(finalSequence, trainingSessionId, token).then(r => r);
        }
        complete().then(r => r)
    }, [dispatch, finalSequence, trainingSessionId])

    return (
        <div className="grid place-items-center mt-4 ">
            <WrappedTitleCard alignment='grid place-items-center text-center' title='Training Complete'>
                <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}
                            transition={{duration: 0.5}}
                            className={`flex flex-col items-center`}>
                    {/*<img src={TrophyAnimation} alt=""/>*/}
                    <div className="flex flex-wrap  justify-center flex-col items-center gap-5 m-4">
                        <Pingwin className="w-[250px] h-[250px] md:w-[400px] md:h-[400px]"/>
                        <div className="flex flex-wrap gap-5 w-full">
                            <div>
                                <InformationalCards
                                    value={`${t('Accuracy')} ${accuracyCount}%`}
                                    progress={accuracyProgress}
                                />
                                <InformationalCards
                                    value={`${t('Mistakes')} ${numberOfMistakes}`}
                                    progress={(numberOfMistakes / totalExercisesCount) * 100}
                                />
                            </div>
                            <div>
                                <InformationalCards
                                    value={`${t('Exercises')} ${totalExercisesCount}`}
                                    progress={100}
                                />
                                <InformationalCards
                                    value={`${t('Hints')} ${numberOfHints}`}
                                    progress={(numberOfHints / totalExercisesCount) * 100}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-center  gap-3 flex-col-reverse  w-full p-4">
                        <button
                            className="button border-button-color hover:bg-button-color hover:bg-button-color-light hover:text-white font-bold  py-2 px-8 rounded text-sm transition duration-300 ease-in-out"
                            onClick={() => clickHandler(`/${i18n.language}/app/vocabularies`)}>
                            Back to Vocabularies
                        </button>
                        <button
                            className="button hover:bg-button-color-light hover:text-white bg-button-color py-2 px-8 rounded text-sm gap-6 text-white font-bold  transition duration-300 ease-in-out"
                            onClick={continueTraining}>
                            Continue training
                        </button>
                    </div>
                    {/* add button if hard training is available */}
                </motion.div>
            </WrappedTitleCard>
        </div>
)
}

export default TrainingComplete
