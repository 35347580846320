import {forwardRef, Ref} from "react"
import {motion} from "framer-motion"

const TrainingImage = forwardRef(({ src, className }: {src: string, className?: string}, ref: Ref<HTMLImageElement>) => {
    return (
        <motion.img   initial={{opacity: 0}}
                      animate={{opacity: 1}}
                      transition={{duration: 0.5}} ref={ref} src={src ? `${src}&w=400&dpr=1` : `${process.env.PUBLIC_URL}/empty.png` } alt='' className={`training-image animation ${className}`} />
    ) 
});

export default TrainingImage