import {Textarea} from "@mui/joy";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {showNotification} from "../../store/headerSlice";
import {axios_post} from "../../utils/globalUtils";
import InputText from "../../components/Input/InputText";
import {ButtonBack} from "../../components/WordDetails/ButtonBack";
import ErrorText from "../../components/Typography/ErrorText";


type ContactUsPageFormType = {
    email: string;
    header: string;
    name: string;
    lastname: string;
    description: string;
};

const INITIAL_FORM_OBJ: ContactUsPageFormType = {
    email: "",
    header: "",
    name: "",
    lastname: "",
    description: "",
};


const ContactUsPage = () => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [formObject, setFormObject] = useState<ContactUsPageFormType>(INITIAL_FORM_OBJ);
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const updateFormValue = (updateType: keyof ContactUsPageFormType, value: string) => {
        setFormObject({ ...formObject, [updateType]: value });
    }
    const updateContactUs = () => {
        if (window.location.pathname === '/contact-us') {
            dispatch(showNotification({ message: "You have successfully sent your message.", status: 1 }));
        }
    }

    const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
            await axios_post("/scripts/contactUs", {
                email: formObject.email,
                header: formObject.header,
                name: formObject.name + " " + formObject.lastname,
                description: formObject.description
            });
            dispatch(
                showNotification({
                    status: 1,
                    message: "You have successfully sent your message.",
                })
            );
            setFormObject(INITIAL_FORM_OBJ);
        } catch (error) {
            setErrorMessage(String(error));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="w-full max-w-[64rem] m-auto lg:p-0 p-4 mt-2 flex-1">
            <div className="flex flex-col gap-4">
                <ButtonBack className="lg:pt-4" backUrl="/" />
                <form onSubmit={submitForm}>
                    <h1 className="text-4xl font-bold text-center mb-6">{t("ContactUsPageMainText")}</h1>
                    <div className="mb-4 flex flex-col">
                        <div className="flex gap-4">
                            <InputText
                                required
                                type="text"
                                value={formObject.name}
                                placeholder={t("ContactUsPageInputPlaceholderName")}
                                containerStyle="mt-4 text-base"

                                updateFormValue={(e) => updateFormValue("name", e.target.value)}
                            />
                            <InputText
                                required
                                type="text"
                                value={formObject.lastname}
                                placeholder={t("ContactUsPageInputPlaceholderLastName")}
                                containerStyle="mt-4 text-base"

                                updateFormValue={(e) => updateFormValue("lastname", e.target.value)}
                            />
                        </div>
                        <InputText
                            required
                            type="email"
                            value={formObject.email}
                            placeholder={t("ContactUsPageInputPlaceholderEmail")}
                            containerStyle="mt-4 text-base"

                            updateFormValue={(e) => updateFormValue("email", e.target.value)}
                        />
                        <InputText
                            required
                            type="text"
                            value={formObject.header}
                            placeholder={t("ContactUsPageInputPlaceholderHeader")}
                            containerStyle="mt-4 text-base"

                            updateFormValue={(e) => updateFormValue("header", e.target.value)}
                        />

                        <div className="flex flex-col mt-4  w-full">
                            <Textarea
                                required
                                size="lg"
                                minRows={4}
                                value={formObject.description}
                                placeholder={t("ContactUsPageTextareaPlaceholderDetails")}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => updateFormValue("description", e.target.value)}
                                className="text-black"
                                sx={{ "background-color": "white" }}
                            />
                        </div>
                    </div>

                    <ErrorText styleClass="mt-8">{errorMessage}</ErrorText>
                    <button onClick={() => updateContactUs()} className={"btn-all rounded w-full text-white" + (loading ? " loading" : "")} type="submit">
                        <p className="p-2 font-bold text-white">{t("ContactUsPageSubmitButton")}</p>
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ContactUsPage;
