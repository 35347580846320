import {ChangeEvent, useEffect, useRef} from 'react';

export type CompleteEmptySpacesInputProps = {
    width: string;
    input: string;
    trainingExampleId: number;
    onChangeEvent: (e: ChangeEvent<HTMLInputElement>) => void;
    correct: boolean;
}

const TrainingInput = ({ width, input, trainingExampleId, onChangeEvent, correct }: CompleteEmptySpacesInputProps) => {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        ref.current?.focus();
    }, [])

    return (
        <input
            tabIndex={0}
            autoFocus
            readOnly={correct}
            style={{ width: width }}
            ref={ref}
            value={input}
            key={trainingExampleId}
            onChange={onChangeEvent}
            autoComplete="off" autoCorrect="off" spellCheck="false" autoCapitalize="off"
            className={`training-input my-3 ml-2  ${correct ? "bg-green-50 border-2 border-green-400" : "bg-slate-50 border-2 border-blue-200"}`}
        />
    )
}

export default TrainingInput