import {useState} from "react";
import {TextField} from "@mui/material";
import {ReactComponent as HidePasswordIcon} from '../../assets/eye-password-hide.svg';
import {ReactComponent as ShowPasswordIcon} from '../../assets/eye-show.svg';
import {useTranslation} from "react-i18next";

const PasswordInput = ({onChange}) => {
    const [showPassword, setShowPassword] = useState(false);
    const {t} = useTranslation();
    const changePasswordInputState = () => {
        setShowPassword(!showPassword)
    }

    return (
        <div className='w-full relative bg-white'>
            <TextField autoComplete="current-password" id="outlined-basic"  label={t('Password')} className='input text-xl input-bordered w-full bg-white'
                       type={showPassword ? "text" : "password"}  onChange={onChange} />
            <button type='button' className='absolute top-[1.2rem] right-3' onClick={changePasswordInputState}>
                {showPassword ? <ShowPasswordIcon className='w-5 h-5' /> : <HidePasswordIcon className='w-5 h-5' />}
            </button>
        </div>
    )
}

export default PasswordInput
