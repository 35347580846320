import {ChangeEvent, FormEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {BASE_URL} from "../../constant";
import {showNotification} from "../../store/headerSlice";
import {check400} from "../../utils/globalUtils";
import PasswordInput from "./PasswordInput";
import UnauthorizedFormComponent from "./UnauthorizedFormComponent";
import ErrorText from "../../components/Typography/ErrorText";


function NewPassword() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState<string>("");
    const { t } = useTranslation();
    async function submitForm(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setLoading(true);
        const res = await fetch(`${BASE_URL}/auth/forgotPassword/${token}?newPassword=${newPassword}`);
        setLoading(false);

        if (check400(res.status)) {
            setErrorMessage(t('PasswordChangedError'));
            return;
        }
        dispatch(showNotification({
            status: 1,
            message: t('PasswordChanged')
        }))
        setTimeout(() => {
            navigate('/login')
        }, 750)
    }

    return (
        <UnauthorizedFormComponent>
            <div className='py-2 px-4 lg:py-24 lg:px-10 bg-white rounded-lg'>
                <h2 className='text-2xl font-semibold mb-2 text-center'>{t('NewPassword')}</h2>

                <form onSubmit={submitForm}>
                    <div className="my-4">
                        <PasswordInput onChange={(e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)} />
                    </div>

                    <ErrorText styleClass="mt-12">{errorMessage}</ErrorText>
                    <button type="submit"
                        className={"btn border-0 mt-2 w-full text-white bg-button-color hover:bg-blue-500" + (loading ? " loading" : "")}>{t('ResetPassword')}
                    </button>
                </form>
            </div>
        </UnauthorizedFormComponent>
    )
}

export default NewPassword;