import i18n from "i18next";
import {lazy, useCallback, useEffect, useState} from "react";
import {NotificationContainer, NotificationManager,} from "react-notifications";
import "react-notifications/lib/notifications.css";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Route, Routes, useLocation, useMatch, useNavigate} from "react-router-dom";
import "./App.css";
import initializeApp from "./app/init";
import MainPageContent from "./components/MainPage/MainPageContent";
import Training from "./components/Training/Training";
import {BASE_URL} from "./constant";
import SuspenseContent from "./containers/SuspenseContent";
import NewPassword from "./features/user/NewPassword";
import Auth from "./pages/Auth";
import FormLearning from "./pages/FormLearning";
import FormNative from "./pages/FormNative";
import ContactUsPage from "./pages/protected/ContactUs";
import CookiesComponent from "./pages/protected/CookiesComponent";
import {removeNotificationMessage} from "./store/headerSlice";
import {selectToken} from "./store/user/userSelector";
import {selectCurrentVocabularies} from "./store/vocabulary/vocabularySelector";
import {LOCAL} from "./i18nf/constants";

const Layout = lazy(() => import("./containers/Layout"));
const MainPage = lazy(() => import("./pages/MainPage"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));

initializeApp();

function App() {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const vocabularies = useSelector(selectCurrentVocabularies);
    const {newNotificationMessage, newNotificationStatus} = useSelector(
        (state) => state.header,
    );
    const navigate = useNavigate();
    const match = useMatch(":lang/*");
    const isValided = JSON.parse(sessionStorage.isValided || "{}")?.valid;
    console.log("🚀 ~ App ~ isValided:", isValided);
    const [isValid, setIsValid] = useState(isValided || false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    console.log(i18n.language)

    useEffect(() => {
        const checkTokenValidity = async () => {
            if (token) {
                setLoading(true);
                try {
                    const res = await fetch(
                        `${BASE_URL}/user/isTokenValid?token=${token}`,
                    );
                    const data = await res.json();
                    setIsValid(data.valid);
                    sessionStorage.setItem(
                        "isValided",
                        JSON.stringify({
                            valid: data.valid,
                            token,
                        }),
                    );

                    if (
                        !data.valid &&
                        (window.location.href.includes("app") ||
                            window.location.href.includes("training"))
                    ) {
                        navigate(`/${i18n.language}/login`);
                    }
                } catch (error) {
                    console.error("Error checking token validity:", error);
                }
            }
            setLoading(false);
        };

        if (!isValid) {
            checkTokenValidity().then();
        }
    }, [isValid, navigate, token]);
    useEffect(() => {
        const currentURL = location.pathname;
        const referrer = document.referrer;
        const lang18n = i18n.language;
        let langFromUrl = currentURL.split("/")[1];


        console.log(i18n.language);
        console.log(referrer);
        console.log(i18n.language);
        console.log(referrer);

        if (langFromUrl !== lang18n) {
            console.log(langFromUrl);
            console.log(i18n.language)
            i18n.changeLanguage(lang18n);
            navigate(`${location.pathname.replace(langFromUrl, lang18n)}`, {replace: true});
        }
    }, [location.pathname, navigate]);

    const changeLanguage = useCallback((lang, enRedirect) => {
        const path = window.location.pathname;
        console.log(path)
        if (lang && (LOCAL.EN === lang || LOCAL.DE === lang || LOCAL.ES === lang)) {
            if (i18n.language !== lang) {
                console.log("Lang", lang)
                console.log("i18n", i18n.language)
                navigate(path.replace(i18n.language, lang), {replace: true});
                i18n.changeLanguage(lang);
            }
        } else if (enRedirect) {
            i18n.changeLanguage('en');
            navigate('en')
        }
    }, [navigate]);


    useEffect(() => {
        const currentURL = location.pathname;

        if (match) {
            const langFromUrl = match.params.lang;
            const targetLanguage = i18n.language;

            if (langFromUrl && langFromUrl !== targetLanguage) {
                changeLanguage(targetLanguage, true);
                const newUrl = currentURL.replace(langFromUrl, targetLanguage);
                navigate(newUrl, {replace: true});
            }
        }

    }, [changeLanguage, location.pathname, match, navigate]);

    // useEffect(() => {
    //   if (match) {
    //     const { lang } = match.params;
    //     changeLanguage(String(lang), true);
    //   }
    // }, [changeLanguage, match]);

    useEffect(() => {
        if (newNotificationMessage !== "") {
            if (newNotificationStatus === 2) NotificationManager.info(newNotificationMessage, 'Info')
            else if (newNotificationStatus === 1) NotificationManager.success(newNotificationMessage, 'Success')
            else if (newNotificationStatus === 0) NotificationManager.error(newNotificationMessage, 'Error')
            dispatch(removeNotificationMessage())
        }
    }, [newNotificationMessage, dispatch, newNotificationStatus]);

    if (loading) {
        return <SuspenseContent/>;
    }

    return (
        <>
            <NotificationContainer/>
            <Routes>
                <Route index element={<Navigate to={i18n.language}/>}/>
                <Route path=":lang" element={<MainPage isValid={isValid}/>}>
                    <Route index element={<MainPageContent/>}/>
                    <Route path="contact-us" element={<ContactUsPage/>}/>
                </Route>

                <Route path=":lang/training">
                    <Route index element={<Training/>}/>
                    <Route path="*" element={<Navigate to={`/${i18n.language}/training`}/>}/>
                </Route>

                <Route path=":lang">
                    <Route path="login" element={<Login/>}/>
                    <Route path="forgot-password" element={<ForgotPassword/>}/>
                    <Route path="register" element={<Register/>}/>
                    <Route path="new-password/:token" element={<NewPassword/>}/>
                    <Route path="auth" element={<Auth/>}/>

                    <Route path="app/*" element={<Layout/>}/>

                    <Route path="form">
                        <Route path="learning" element={<FormLearning token={token} vocabularies={vocabularies}/>}/>
                        <Route path="native" element={<FormNative/>}/>
                    </Route>
                </Route>
                <Route path="*" element={<Navigate to={`/${i18n.language}`} replace/>}/>
            </Routes>
            <CookiesComponent/>
        </>
    );
}

export default App;
