import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {BASE_URL} from "../constant";
import SuspenseContent from "../containers/SuspenseContent";
import i18n from "../i18nf/i18n";
import {setToken, setUserDetails} from "../store/user/userSlice";
import {axios_get} from "../utils/globalUtils";
import {setCurrentlySelectedVocabulary, setVocabularies, VocabularyType,} from "../store/vocabulary/vocabularySlice";
import {processVocabularies} from "../utils/vocabularyUtils";
import {AuthenticationResponse} from "../utils/userUtils";

const Auth = () => {
  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (tokenParam) {
      dispatch(setToken(tokenParam));
      const fetchUserDetails = async () => {
        if (!tokenParam) return false;

        let res: AuthenticationResponse;
        try {
          res = await axios_get(`${BASE_URL}/user/getUserDetails`, {
            headers: {
              Authorization: `Bearer ${tokenParam}`,
            },
          });
          dispatch(setUserDetails(res));
        } catch (error) {
          console.error("Error fetching user details:", error);
          return false;
        }

        const fetchedVoc = await processVocabularies(tokenParam);

        if (fetchedVoc.length === 0) {
          navigate(`/${i18n.language}/form/learning`);
          return false;
        } else {
          dispatch(setVocabularies(fetchedVoc));
          if (!res.lastPickedVocabularyId) {
            dispatch(setCurrentlySelectedVocabulary(fetchedVoc[0]));
          } else {
            const element = fetchedVoc.find(
              (v: VocabularyType) => v.id === res.lastPickedVocabularyId,
            );
            if (element) dispatch(setCurrentlySelectedVocabulary(element));
            else dispatch(setCurrentlySelectedVocabulary(fetchedVoc[0]));
          }
          navigate(
            `/${i18n.language}/app/vocabularies/${res.lastPickedVocabularyId}`,
          );
          return true;
        }
      };

      const checkToken = async () => {
        const res = await fetch(
          `${BASE_URL}/user/isTokenValid?token=${tokenParam}`,
        );
        const data = await res.json();
        if (!data.valid) {
          navigate(`/${i18n.language}/login`);
        }
        return data.valid;
      };

      let flag = false;

      checkToken().then((e: boolean) => {
        flag = e;
      });

      fetchUserDetails().then((e: boolean) => {
        flag = e;
      });

      if (flag) navigate(`/${i18n.language}/app/vocabularies`);
    }
  }, [dispatch, navigate, tokenParam]);

  return <SuspenseContent size={100} />;
};

export default Auth;
