import {ReactElement} from 'react';
import {ReactComponent as GermanFlag} from '../assets/flag-germany.svg';
import {ReactComponent as SpainFlag} from '../assets/flag-spain.svg';
import {ReactComponent as UKFlag} from "../assets/flag-united-kingdom.svg";
import {ReactComponent as PlusIcon} from "../assets/plus.svg";
import {ReactComponent as RussianFlag} from '../assets/flag-russia.svg';
import {Language} from '../store/languageSlice';

export function pickIcon(language: Language | null, learning: boolean = false, classname?: string): ReactElement {
    if (!language) return <></>;

    const className = classname ?? (learning ? 'w-14 h-14' : 'w-[1.6rem] h-8');

    switch (language) {
        case 'GERMAN':
            return <GermanFlag className={className} />
        case 'SPANISH':
            return <SpainFlag className={className} />
        case 'ENGLISH':
            return <UKFlag className={className} />
        case 'RUSSIAN':
            return <RussianFlag className={className}/>
        default:
            return <PlusIcon className={className} />
    }
}